import React from 'react';
import './Terms.css';
import {Helmet} from 'react-helmet-async';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <Helmet>
      <link rel="canonical" href={window.location.href} />
        <title>Terms and Conditions | Beat My Insurance</title>
        <meta
          name="description"
          content="Review Terms and Conditions on Beat My Insurance. Know your rights and responsibilities as a buyer or seller."
        />
        <meta
          property="og:title"
          content="Terms and Conditions | Beat My Insurance"
        />
        <meta
          property="og:description"
          content="Review Terms and Conditions on Beat My Insurance. Know your rights and responsibilities as a buyer or seller."
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Beat My Insurance" />
        <meta name="twitter:card" content="summary_large_image" />{" "}
        <meta
          name="twitter:title"
          content="Terms and Conditions | Beat My Insurance"
        />{" "}
        <meta
          name="twitter:description"
          content="Review Terms and Conditions on Beat My Insurance. Know your rights and responsibilities as a buyer or seller."
        />{" "}
        <meta
          name="twitter:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />{" "}
        <meta name="twitter:site" content="@Beatmyinsurance" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            "@id": "#Organization",
            url: "https://www.beatmyinsurance.com",
            legalName: "Beat My Insurance",
            name: "Beat My Insurance",
            description:
              "Get the best auto, home, life, and super visa insurance with Beat My Insurance! Easily compare options to find cheap car insurance, auto insurance, home insurance in Canada, and bundled home and auto insurance deals.",
            image: "https://www.beatmyinsurance.com/logo.png",
            logo: "https://www.beatmyinsurance.com/logo.png",
            
            email: "info@beatmyinsurance.com",
            address: {
              "@type": "PostalAddress",
              addressRegion: "Ontario",
              addressCountry: "Canada",
              
            },
            sameAs: [
              "https://www.facebook.com/beatmyinsurance/",
              "https://www.youtube.com/@BeatMyInsurance",
              "https://www.instagram.com/beatmyinsurance/",
              "https://www.linkedin.com/company/beat-my-insurance/",
              "https://x.com/Beatmyinsurance",
            ],
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Beat My Insurance",
                item: "https://www.beatmyinsurance.com",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Buy Insurance",
                item: "https://www.beatmyinsurance.com/buy-insurance",
              },
              {
                "@type": "ListItem",
                position: 3,
                name: "Sell Insurance",
                item: "https://www.beatmyinsurance.com/sell-insurance",
              },
              {
                "@type": "ListItem",
                position: 4,
                name: "Cheap Insurance Car Finder",
                item: "https://www.beatmyinsurance.com/cheap-insurance-car-finder",
              },
              {
                "@type": "ListItem",
                position: 5,
                name: "Mortgage Penalty Calculator",
                item: "https://www.beatmyinsurance.com/mortgage-penalty-calculator",
              },
              {
                "@type": "ListItem",
                position: 6,
                name: "About Us",
                item: "https://www.beatmyinsurance.com/about",
              },
            ],
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "WebPage",
            "@id": "#WebPage",
            url: "https://www.beatmyinsurance.com/cheap-insurance-car-finder",
            name: "Cheap Insurance Car Finder",
            areaServed: [
              "https://en.wikipedia.org/wiki/Canada",
              "https://en.wikipedia.org/wiki/Ottawa",
              "https://en.wikipedia.org/wiki/London,_Ontario",
              "https://en.wikipedia.org/wiki/Hamilton,_Ontario",
              "https://en.wikipedia.org/wiki/Vaughan",
              "https://en.wikipedia.org/wiki/Toronto",
              "https://en.wikipedia.org/wiki/Mississauga",
              "https://en.wikipedia.org/wiki/Brampton",
              "https://en.wikipedia.org/wiki/Windsor,_Ontario",
              "https://en.wikipedia.org/wiki/Kitchener,_Ontario",
              "https://en.wikipedia.org/wiki/Markham,_Ontario",
            ],
          })}
        </script>
      </Helmet>
      <h1>Terms and Conditions</h1>
<p>Last Updated: January 2, 2025</p>

<p><strong>CRITICAL PLATFORM USAGE WARNING:</strong> BeatMyInsurance.com strongly advises against using our platform if you have ANY doubts about your ability to verify insurance providers independently. While we may perform basic online verification of brokers/agents using publicly available information, we take NO responsibility for their credentials, status, or legitimacy. Users MUST perform their own thorough verification before any engagement.</p>

<h2>1. Definitions and Scope</h2>
<ul>
  <li>
    <strong>1.1. In these Terms and Conditions:</strong>
    <ul>
      <li>"Platform" refers to BeatMyInsurance.com and all its associated services</li>
      <li>"User" or "You" refers to any individual or entity accessing or using the Platform</li>
      <li>"We," "Us," or "Our" refers to BeatMyInsurance.com and its operators</li>
      <li>"Services" refers to all insurance comparison and related functionalities provided through the Platform</li>
      <li>"Provider" refers to third-party insurance companies and their products listed on our Platform</li>
      <li>"Content" refers to all material, information, and data available on the Platform</li>
    </ul>
  </li>
  <li>
    <strong>1.2. Acceptance of Terms</strong>
    <ul>
      <li>You have read, understood, and agree to be bound by these terms</li>
      <li>You have the legal capacity to enter into this agreement</li>
      <li>If representing an organization, you have the authority to bind that organization</li>
      <li>These terms are legally binding and non-negotiable</li>
    </ul>
  </li>
</ul>

<h2>2. Service Description and Limitations</h2>
<ul>
  <li>
    <strong>2.1. Core Services</strong>
    <ul>
      <li>We are an IT services company operating an insurance marketplace platform</li>
      <li>Our platform, BeatMyInsurance.com, serves as a marketplace connecting buyers seeking insurance products with insurance brokers and agents selling insurance products</li>
      <li>We do not sell insurance products directly</li>
      <li>We facilitate connections between buyers and insurance sellers</li>
      <li>We provide estimated quotes and comparative analysis tools</li>
      <li>Our role is strictly limited to providing the technological infrastructure for the marketplace</li>
    </ul>
  </li>
  <li>
    <strong>2.2. Service Limitations</strong>
    <ul>
      <li>All quotes are estimates and subject to final verification by providers</li>
      <li>Service availability is not guaranteed and may vary by region</li>
      <li>We do not guarantee the availability of any specific insurance product</li>
      <li>Provider offerings may change without notice</li>
    </ul>
  </li>
  <li>
    <strong>2.3. Platform Modifications</strong>
    <ul>
      <li>We reserve the right to modify, suspend, or discontinue any aspect of the service</li>
      <li>Implement new features or remove existing ones</li>
      <li>Adjust comparison algorithms and methodologies</li>
      <li>Change operating hours or service availability</li>
      <li>Update platform requirements or specifications</li>
    </ul>
  </li>
</ul>

<h2>3. User Responsibilities and Obligations</h2>
<ul>
  <li>
    <strong>3.1. User Responsibilities and Due Diligence</strong>
    <ul>
      <li>Users are solely responsible for ALL actions taken on the platform</li>
      <li>Users must conduct thorough due diligence before any engagement</li>
      <li>Platform use is entirely at the user's own risk and discretion</li>
      <li>Users MUST independently verify broker credentials, policy terms, and provider legitimacy</li>
    </ul>
  </li>
  <li>
    <strong>3.2. Prohibited Activities</strong>
    <ul>
      <li>Creating multiple accounts or sharing accounts</li>
      <li>Using automated systems or bots to access the platform</li>
      <li>Engaging in data scraping or unauthorized data collection</li>
      <li>Sharing external communication methods or contact details</li>
    </ul>
  </li>
</ul>

<h2>4. Privacy and Data Protection</h2>
<ul>
  <li>We collect and use data for service provision, communication, and analytics.</li>
  <li>Data may be shared with insurance providers, service partners, and legal authorities as required.</li>
  <li>We implement industry-standard security measures to protect user data.</li>
</ul>

<h2>5. Communication Policies</h2>
<ul>
  <li>All communications must occur through platform channels.</li>
  <li>External contact information sharing is prohibited.</li>
</ul>

<h2>6. Financial Terms and Refund Policy</h2>
<ul>
  <li>All transactions are final and non-refundable.</li>
  <li>Users are responsible for all applicable fees and taxes.</li>
</ul>

<h2>7. Liability and Disclaimers</h2>
<ul>
  <li>Services are provided "as is" without warranties or guarantees.</li>
  <li>We assume no liability for any aspect of platform use.</li>
</ul>

<h2>8. Intellectual Property Rights</h2>
<ul>
  <li>All platform content is protected by intellectual property laws.</li>
  <li>Users retain rights to their submissions but grant us a license to use them.</li>
</ul>

<h2>9. Enforcement and Termination</h2>
<ul>
  <li>We reserve the right to monitor activity, remove content, and terminate accounts.</li>
</ul>

<h2>10. Legal Framework</h2>
<ul>
  <li>These terms are governed by Ontario, Canada law.</li>
  <li>Disputes are subject to mandatory arbitration and exclusive jurisdiction in Ontario courts.</li>
</ul>

<h2>Contact and Support</h2>
<p>For inquiries, contact us at <a href="mailto:info@beatmyinsurance.com">info@beatmyinsurance.com</a>.</p>

    </div>
  );
};

export default TermsAndConditions;
