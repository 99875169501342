import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import getMetaTags from "./MetaTags";
import "./Main.css";
import NotificationService from "../services/notificationService";
import Dashboard from "./Dashboard";
import GetPosts from "./GetPosts";
import NewListing from "./NewListing";
import Notifications from "./Notifications";
import Chats from "./Chats";
import Profile from "./Profile";
import Settings from "./Settings";
import Faq from "./Faq";
import Help from "./Help";
import Payments from "./Payments";
import userService from "../services/userService";
import SessionExpired from "./SessionExpired";
import { fetchChatsCount } from "../services/messageService";

const Main = () => {
  const [showApp, setShowApp] = useState("dashboard");
  const [notificationCount, setNotificationCount] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [userImage, setuserImage] = useState("/profile.png");
  const [sessionExpired, setSessionExpired] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [tutorialPart, setTutorialPart] = useState("buyer");
  const [buyerPage, setBuyerPage] = useState(1);
  const [sellerPage, setSellerPage] = useState(1);
  const [user, setUser] = useState();
  const [unreadChats, setUnreadChats] = useState(0);

  const { section } = useParams();
  const tutorialRef = useRef(null);
  useEffect(() => {
    if (section) {
      setShowApp(String(section));
    }
    if (
      localStorage.getItem("BMI-newlisting") &&
      localStorage.getItem("BMI-selectedPost")
    ) {
      localStorage.removeItem("BMI-selectedPost");
      setShowApp("buyer-post");
    } else if (localStorage.getItem("BMI-newlisting")) {
      setShowApp("buyer-post");
    } else if (localStorage.getItem("BMI-selectedPost")) {
      setShowApp("provider-search");
    }
  }, [section]);

  useEffect(() => {
    let notificationIntervalId;
    let chatsUnreadIntervalId;
  
    const fetchUserAndNotifications = async () => {
      const token = getCookie("BMI-token");
  
      if (token) {
        try {
          const response = await userService.getUser(token);
  
          if (response.user) {
            setUser(response.user);
            setuserImage(response.user.image || "/profile.png");

            //ask for mobile verification
            if(!section && !response.user.mobileVerified){
              navigate(`/mobile-verification/${response.user._id}`);
            }
  
            // Fetch notifications after setting the user
            fetchNotificationCount(response.user._id);
  
            // Set interval to fetch notifications every 5 seconds
            notificationIntervalId = setInterval(() => {
              fetchNotificationCount(response.user._id);
            }, 12000);

            // Fetch chats unread count immediately
            fetchChatsUnreadCount(response.user._id);
        
            // Set interval to fetch chats unread count every 8 seconds
            chatsUnreadIntervalId = setInterval(() => {
              fetchChatsUnreadCount(response.user._id);
            }, 12000);
          } else if (response.expired) {
            setSessionExpired(true);
          } else {
            alert(response.error);
          }
        } catch (error) {
          console.error("Error fetching user:", error);
          setUser(null);
        }
      } else {
        console.log("No token found");
        setUser(null);
        setSessionExpired(true);
      }
      setIsLoading(false);
    };
  
    const initialize = async () => {
      await fetchUserAndNotifications();
    };
  
    initialize();
  
    // Cleanup function to clear intervals on component unmount
    return () => {
      if (notificationIntervalId) clearInterval(notificationIntervalId);
      if (chatsUnreadIntervalId) clearInterval(chatsUnreadIntervalId);
    };
  }, []);
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tutorialRef.current && !tutorialRef.current.contains(event.target)) {
        setShowTutorial(false);
        setBuyerPage(1);
        setSellerPage(1);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchNotificationCount = async (userId) => {
    try {
      const data = await NotificationService.getNotificationsForUser(userId);

      const unreadCount = data.filter(
        (notification) => !notification.read
      ).length;
      setNotificationCount(unreadCount);
    } catch (error) {
      console.log("Failed to fetch notification count:", error.message);
    }
  };

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  }

  const handleLogout = () => {
    document.cookie = "BMI-token=; path=/; max-age=0; secure; SameSite=Strict";
    localStorage.setItem("isLogged", false);
    navigate("/home");
  };
  const handleLeftClick = () => {
    if (tutorialPart === "buyer" && buyerPage > 1) {
      setBuyerPage(buyerPage - 1);
    } else if (tutorialPart === "seller" && sellerPage > 1) {
      setSellerPage(sellerPage - 1);
    }
  };

  const handleRightClick = () => {
    if (tutorialPart === "buyer" && buyerPage < 5) {
      setBuyerPage(buyerPage + 1);
    } else if (tutorialPart === "seller" && sellerPage < 5) {
      setSellerPage(sellerPage + 1);
    }
  };

  const fetchChatsUnreadCount = async(userId) => {
    try{
      if(userId){
        const response = await fetchChatsCount(userId);
        if(response.count){
          setUnreadChats(response.count);
        }
        else if(response.error){
          console.log(response.error)
        }
      }
    }catch(error){
      console.error(error,'Unable to fetch unread chats count');
    }
  }


  return (
    <div className="dashboard-page">
      {getMetaTags(showApp ? showApp : "")}
      {sessionExpired && <SessionExpired />}
      <div
        className={
          showMenu ? "left-menu-container-active" : "left-menu-container"
        }
      >
        <img
          className="logo-dashboard-img"
          src="/logo.png"
          alt="logo"
          onClick={() => window.location.reload()}
        />
        {showMenu && (
          <p
            className="menu-close-main"
            onClick={() => {
              setShowMenu(false);
            }}
          >
            X
          </p>
        )}
        <div className="menu-container-dashboard">
          <div
            className={
              window.location.href.includes("/main/dashboard")
                ? "menu-item-box-active"
                : "menu-item-box"
            }
            onClick={() => {
              window.location.href = "/main/dashboard";
              setShowMenu(false);
            }}
          >
            <img
              src={
                window.location.href.includes("/main/dashboard")
                  ? "/dashboardactive.svg"
                  : "/dashboardtest.png"
              }
              alt="dashboard"
            />
            <p>Dashboard</p>
          </div>

          <div
            className={
              window.location.href.includes("/main/provider-search")
                ? "menu-item-box-active"
                : "menu-item-box"
            }
            id="menuitem-provider-search"
            onClick={() => {
              window.location.href = "/main/provider-search";
              setShowMenu(false);
            }}
          >
            <img
              src={
                window.location.href.includes("/main/provider-search")
                  ? "/searchactive.svg"
                  : "/searchtest.png"
              }
              alt="search"
            />
            <p>Sell Insurance</p>
            <div className="provider-search-hover">
              <p>Find Active Insurance Leads</p>
            </div>
          </div>

          <div
            className={
              window.location.href.includes("/main/buyer-post")
                ? "menu-item-box-active"
                : "menu-item-box"
            }
            id="menuitem-buyer-post"
            onClick={() => {
              window.location.href = "/main/buyer-post";
              setShowMenu(false);
            }}
          >
            <img
              src={
                window.location.href.includes("/main/buyer-post")
                  ? "/plusactive.svg"
                  : "/plustest.png"
              }
              alt="plus"
            />
            <p>Buy Insurance</p>
            <div className="buyer-post-hover">
              <p>Post Your Insurance Needs</p>
            </div>
          </div>

          <div
            className={
              window.location.href.includes("/main/notifications")
                ? "menu-item-box-active"
                : "menu-item-box"
            }
            onClick={() => {
              window.location.href = "/main/notifications";
              setShowMenu(false);
            }}
          >
            <img
              src={
                window.location.href.includes("/main/notifications")
                  ? "/bellactive.svg"
                  : "/belltest.png"
              }
              alt="bell"
            />
            <p>Notifications</p>
          </div>

          <div
            className={
              window.location.href.includes("/main/chats")
                ? "menu-item-box-active"
                : "menu-item-box"
            }
            onClick={() => {
              window.location.href = "/main/chats";
              setShowMenu(false);
            }}
          >
            <img
              src={
                window.location.href.includes("/main/chats")
                  ? "/chatactive.png"
                  : "/chatinactive.png"
              }
              alt="bell"
            />
            <p>Chats</p>
          </div>

          <div
            className={
              window.location.href.includes("/main/profile")
                ? "menu-item-box-active"
                : "menu-item-box"
            }
            onClick={() => {
              window.location.href = "/main/profile";
              setShowMenu(false);
            }}
          >
            <img
              src={
                window.location.href.includes("/main/profile")
                  ? "/profileactive.svg"
                  : "/profiletest.png"
              }
              alt="profile"
            />
            <p>Profile</p>
          </div>

          <div
            className={
              window.location.href.includes("/main/settings")
                ? "menu-item-box-active"
                : "menu-item-box"
            }
            onClick={() => {
              window.location.href = "/main/settings";
              setShowMenu(false);
            }}
          >
            <img
              src={
                window.location.href.includes("/main/settings")
                  ? "/settingsactive.svg"
                  : "/settingstest.png"
              }
              alt="settings"
            />
            <p>Settings</p>
          </div>

          <div
            className={
              window.location.href.includes("/main/payments")
                ? "menu-item-box-active"
                : "menu-item-box"
            }
            onClick={() => {
              window.location.href = "/main/payments";
              setShowMenu(false);
            }}
          >
            <img
              src={
                window.location.href.includes("/main/payments")
                  ? "/paymentsactive.svg"
                  : "/paymentstest.png"
              }
              alt="payments"
            />
            <p>Payments</p>
          </div> 

          <div
            className={
              window.location.href.includes("/main/support")
                ? "menu-item-box-active"
                : "menu-item-box"
            }
            onClick={() => {
              window.location.href = "/main/support";
              setShowMenu(false);
            }}
          >
            <img
              src={
                window.location.href.includes("/main/support")
                  ? "/helpactive.svg"
                  : "/helptest.png"
              }
              alt="help"
            />
            <p>Help & Support</p>
          </div>
        
        <div className="menu-item-box" onClick={() => handleLogout()}>
          <img src="/logouttest.png" alt="logout" />
          <p>Logout</p>
        </div>
        </div>
      </div>
      <img
        onClick={() => {
          setShowMenu(!showMenu);
        }}
        className="menu-icon-main"
        src="/menu.png"
        alt="menu"
      />
      <div className="top-bar-main">
        <div className="top-menu-container">
          <img
            src="/tutorial.png"
            alt="tutorial"
            className="tutorial-icon-m"
            onClick={() => {
              setShowTutorial(!showTutorial);
            }}
          />
          <button
            className="top-post-button"
            onClick={() => {
              setShowTutorial(!showTutorial);
            }}
          >
            
            How it works?
          </button>
          <div
            className="top-chats-icon"
            onClick={() => {
              window.location.href = "/main/chats";
              setShowMenu(false);
            }}
          >
            <div className="chats-count-main">
              {unreadChats > 0 && unreadChats}
            </div>
            <img src="/chats.png" alt="chats" />
          </div>
          <div
            className="top-notifications-icon"
            onClick={() => {
              window.location.href = "/main/notifications";
              setShowMenu(false);
            }}
          >
            <div className="notifications-count-main">
              {notificationCount > 0 && notificationCount}
            </div>
            <img src="/notification.png" alt="notifications" />
          </div>
          
          <div
            className="profile-img-main"
            onClick={() => {
              window.location.href = "/main/profile";
              setShowMenu(false);
            }}
            style={{
              backgroundImage: `url(${userImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </div>
      </div>
      {showTutorial && (
        <div className="tutorial-holder" ref={tutorialRef}>
          <div className="tutorial-bar">
            <button
              className={
                tutorialPart === "buyer"
                  ? "tutorial-btn-active"
                  : "tutorial-btn"
              }
              onClick={() => setTutorialPart("buyer")}
            >
              Buyer
            </button>
            <button
              className={
                tutorialPart === "seller"
                  ? "tutorial-btn-active"
                  : "tutorial-btn"
              }
              onClick={() => setTutorialPart("seller")}
            >
              Seller
            </button>
          </div>
          <div id="web-tutorial" className="tutorial-img-holder">
            <div className="left-arrow-holder">
              {((tutorialPart === "buyer" && buyerPage !== 1) ||
                (tutorialPart === "seller" && sellerPage !== 1)) && (
                <img src="/left.png" onClick={handleLeftClick} />
              )}
            </div>
            {tutorialPart === "buyer" && (
              <img
                className="tutorial-img"
                src={`/buyer_w${buyerPage}.png`}
                alt="instructions"
              />
            )}
            {tutorialPart === "seller" && (
              <img
                className="tutorial-img"
                src={`/seller_w${sellerPage}.png`}
                alt="instructions"
              />
            )}
            <div className="right-arrow-holder">
              {((tutorialPart === "buyer" && buyerPage !== 5) ||
                (tutorialPart === "seller" && sellerPage !== 5)) && (
                <img src="/right.png" onClick={handleRightClick} />
              )}
            </div>
          </div>
          <div id="tab-tutorial" className="tutorial-img-holder">
            <div className="left-arrow-holder">
              {((tutorialPart === "buyer" && buyerPage !== 1) ||
                (tutorialPart === "seller" && sellerPage !== 1)) && (
                <img src="/left.png" onClick={handleLeftClick} />
              )}
            </div>
            {tutorialPart === "buyer" && (
              <img
                className="tutorial-img"
                src={`/buyer_t${buyerPage}.png`}
                alt="instructions"
              />
            )}
            {tutorialPart === "seller" && (
              <img
                className="tutorial-img"
                src={`/seller_t${sellerPage}.png`}
                alt="instructions"
              />
            )}
            <div className="right-arrow-holder">
              {((tutorialPart === "buyer" && buyerPage !== 5) ||
                (tutorialPart === "seller" && sellerPage !== 5)) && (
                <img src="/right.png" onClick={handleRightClick} />
              )}
            </div>
          </div>
          <div id="mobile-tutorial" className="tutorial-img-holder">
            <div className="left-arrow-holder">
              {((tutorialPart === "buyer" && buyerPage !== 1) ||
                (tutorialPart === "seller" && sellerPage !== 1)) && (
                <img src="/left.png" onClick={handleLeftClick} />
              )}
            </div>
            {tutorialPart === "buyer" && (
              <img
                className="tutorial-img"
                src={`/buyer_m${buyerPage}.png`}
                alt="instructions"
              />
            )}
            {tutorialPart === "seller" && (
              <img
                className="tutorial-img"
                src={`/seller_m${sellerPage}.png`}
                alt="instructions"
              />
            )}
            <div className="right-arrow-holder">
              {((tutorialPart === "buyer" && buyerPage !== 5) ||
                (tutorialPart === "seller" && sellerPage !== 5)) && (
                <img src="/right.png" onClick={handleRightClick} />
              )}
            </div>
          </div>
        </div>
      )}

      {user && (
        <div className="content-container-main">
          {showApp === "dashboard" && <Dashboard user={user} />}
          {showApp === "provider-search" && <GetPosts user={user} />}
          {showApp === "buyer-post" && <NewListing user={user} />}
          {showApp === "notifications" && <Notifications user={user} />}
          {showApp === "chats" && <Chats user={user}/>}
          {showApp === "profile" && <Profile user={user} />}
          {showApp === "settings" && <Settings user={user} />}
          {showApp === "faq" && <Faq user={user} />}
          {showApp === "support" && <Help user={user} />}
          {showApp === "payments" && <Payments user={user} />}
        </div>
      )}
    </div>
  );
};

export default Main;
