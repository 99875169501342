import { React, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
//import "./Login.css";
import userService from "../services/userService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetPassword } from "../services/passwordService";
import MobileVerification from "./MobileVerification";

const Login = (login) => {
  const location = useLocation();
  const [newUser, setNewUser] = useState(login);
  const [email, setEmail] = useState();
  const [forgotPassword, setForgotPassword] = useState();
  const [registerCredentials, setRegisterCredentials] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });
  const [termsAgreed, setTermsAgreed] = useState(false);

  const navigate = useNavigate();

  const notifySuccess = (alertMessage) => {
    toast.success(alertMessage);
  };

  const notifyDanger = (alertMessage) => {
    toast.error(alertMessage);
  };
  useEffect(() => {
    if (location.state?.newUser !== undefined) {
      setNewUser(location.state.newUser);
    }
  }, [location.state]);
  useEffect(() => {
    const isLogged = localStorage.getItem("isLogged");
    if (isLogged === "true") {
      navigate("/main");
    }
  }, []);
  //build

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (newUser) {
      setRegisterCredentials({ ...registerCredentials, [name]: value });
    } else if (!newUser) {
      setLoginCredentials({ ...loginCredentials, [name]: value });
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email, password } = registerCredentials;

    if (!termsAgreed) {
      notifyDanger("Please accept Terms&Conditions to proceed");
      return;
    }

    if (!firstName || !email || !password) {
      notifyDanger("All fields are required");
      return;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      notifyDanger("Invalid email format");
      return;
    }

    if (password.length < 8) {
      notifyDanger("Password must be at least 8 characters long");
      return;
    }

    try {
      const response = await userService.register(registerCredentials);
      if (response.message) {
        notifySuccess(response.message);
        setRegisterCredentials({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
        });
        setTermsAgreed(false);
        
      } else {
        notifyDanger(response.error || "Registration failed");
      }
    } catch (error) {
      notifyDanger("Registration failed");
      console.error(error);
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await userService.login(loginCredentials);

      //const user = await userService.getUser(response.message._id);
      //console.log(user);
      if (response.blacklist) {
        notifyDanger(response.blacklist);
      } else if (response.token) {
        // Assuming response.token is the token you want to store
        document.cookie = `BMI-token=${response.token}; path=/; max-age=86400; secure; SameSite=Strict`;
        localStorage.setItem("isLogged", "true");
        notifySuccess("Login Successful");

        navigate("/main");
      } else {
        notifyDanger(response.error || "Something went wrong");
      }
    } catch (error) {
      notifyDanger("Login failed");
      console.error(error);
    }
  };
  const handleForgotPassword = async () => {
    try {
      if (!/\S+@\S+\.\S+/.test(email)) {
        notifyDanger("Invalid email format");
        return;
      }
      const response = await resetPassword({ email: email });
      if (response.message) {
        notifySuccess(response.message);
      } else {
        notifyDanger(response.error || "sorry! something went wrong" + "kkk");
      }
    } catch (error) {
      notifyDanger(error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50 py-16">
      <Helmet>
      <link rel="canonical" href={window.location.href} />
        <title>Login | Connect Insurance Buyers and Sellers in Canada</title>
        <meta
          name="description"
          content="Log in to Beat My Insurance to list your insurance needs as a buyer or get auto insurance leads as a broker. Manage your account with ease."
        />
        <meta
          property="og:title"
          content="Login | Connect Insurance Buyers and Sellers in Canada"
        />
        <meta
          property="og:description"
          content="Log in to Beat My Insurance to list your insurance needs as a buyer or get auto insurance leads as a broker. Manage your account with ease."
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Beat My Insurance" />
        <meta name="twitter:card" content="summary_large_image" />{" "}
        <meta
          name="twitter:title"
          content="Login | Connect Insurance Buyers and Sellers in Canada"
        />{" "}
        <meta
          name="twitter:description"
          content="Log in to Beat My Insurance to list your insurance needs as a buyer or get auto insurance leads as a broker. Manage your account with ease."
        />{" "}
        <meta
          name="twitter:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />{" "}
        <meta name="twitter:site" content="@Beatmyinsurance" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "About Us",
                item: "https://www.beatmyinsurance.com/about",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Login",
                item: "https://www.beatmyinsurance.com/login",
              },
            ],
          })}
        </script>
      </Helmet>
      {newUser ? (
        <div className="flex flex-col items-center justify-center  py-2  bg-white shadow-lg sm:p-8 px-4 md:max-w-lg md:mx-auto rounded-md">
          
          <form onSubmit={handleRegisterSubmit} className="w-full pt-2 pb-6">
            <div className="text-center mb-6">
              <h1 className="text-2xl font-bold text-gray-700">
                Create an account
              </h1>
              <p className="text-sm text-gray-500">Let's get started!</p>
            </div>
            <div className="space-y-4">
              {["firstName", "lastName", "email", "password"].map((field) => (
                <div key={field}>
                  <label
                    htmlFor={field}
                    className="block text-sm font-medium text-gray-600"
                  >
                    {field.charAt(0).toUpperCase() + field.slice(1)}
                  </label>
                  <input
                    id={field}
                    name={field}
                    type={field === "password" ? "password" : "text"}
                    placeholder={`Enter ${field.charAt(0).toUpperCase() + field.slice(1)}`}
                    value={registerCredentials[field]}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
                  />
                </div>
              ))}
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="terms"
                  checked={termsAgreed}
                  onClick={() => setTermsAgreed(!termsAgreed)}
                  className="w-4 h-4 text-blue-900 border-gray-300"
                />
                <label htmlFor="terms" className="text-sm text-gray-600">
                  I agree to the{" "}
                  <a
                    href="https://www.beatmyinsurance.com/terms/"
                    className="text-blue-900 hover:underline"
                  >
                    terms & conditions
                  </a>
                </label>
              </div>
              <button
                onClick={handleRegisterSubmit}
                className="w-full bg-blue-900 text-white py-2 rounded-md hover:bg-blue-600"
              >
                Register
              </button>
            </div>
            <div className="mt-4 flex justify-center text-gray-500">
              <button
                onClick={(e) =>{
                  e.preventDefault();
                  termsAgreed
                    ? (window.location.href =
                        "https://www.beatmyinsurance.com/api/auth/google")
                    : notifyDanger("Agree to terms and conditions!")
                }}
                className="flex items-center space-x-2"
              >
                <img src="google-icon.png" alt="Google" className="w-5 h-5" />
                <span >Sign in with Google</span>
              </button>
            </div>
            <div className="mt-6 text-center">
              <p className="text-sm text-gray-500">
                Already have an account?{" "}
                <span
                  onClick={() => setNewUser(false)}
                  className="text-blue-900 cursor-pointer hover:underline"
                >
                  Login
                </span>
              </p>
            </div>
          </form>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center  p-6 bg-white shadow-lg sm:p-8 md:max-w-lg md:mx-auto rounded-md">
          
          <form onSubmit={handleLoginSubmit} className="w-full">
            <div className="text-center mb-6">
              <h1 className="text-2xl font-bold text-gray-700">Login</h1>
              <p className="text-sm text-gray-500">Welcome back!</p>
            </div>
            <div className="space-y-4">
              {["email", "password"].map((field) => (
                <div key={field}>
                  <label
                    htmlFor={field}
                    className="block text-sm font-medium text-gray-600"
                  >
                    {field.charAt(0).toUpperCase() + field.slice(1)}
                  </label>
                  <input
                    id={field}
                    name={field}
                    type={field}
                    placeholder={`Enter ${field.charAt(0).toUpperCase() + field.slice(1)}`}
                    value={loginCredentials[field]}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
                  />
                </div>
              ))}
              <button
                onClick={handleLoginSubmit}
                className="w-full bg-blue-900 text-white py-2 rounded-md hover:bg-blue-600"
              >
                Login
              </button>
              <p
                onClick={() => setForgotPassword(true)}
                className="text-sm text-blue-900 cursor-pointer mt-2 text-center hover:underline"
              >
                Forgot Password?
              </p>
            </div>
            <div className="mt-4 flex justify-center text-gray-500">
              <button
                onClick={(e) =>{
                  e.preventDefault();
                  (window.location.href =
                        "https://www.beatmyinsurance.com/api/auth/google");
                   
                }}
                className="flex items-center space-x-2"
              >
                <img src="google-icon.png" alt="Google" className="w-5 h-5" />
                <span >Sign in with Google</span>
              </button>
            </div>
            <div className="mt-6 text-center">
              <p className="text-sm text-gray-500">
                Don't have an account?{" "}
                <span
                  onClick={() => setNewUser(true)}
                  className="text-blue-900 cursor-pointer hover:underline"
                >
                  Register
                </span>
              </p>
            </div>
          </form>
          {forgotPassword && (
            <div className="mt-6">
              <label className="block text-sm font-medium text-gray-600">
                Enter your registered email
              </label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email here"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
              />
              <button
                onClick={handleForgotPassword}
                className="w-full bg-blue-900 text-white py-2 mt-2 rounded-md hover:bg-blue-600"
              >
                Send Mail
              </button>
            </div>
          )}
        </div>
      )}
      <div className="mt-12 text-center text-gray-500">
        <p>
          Copyright 2024 BMI. All Rights Reserved |{" "}
          <a
            href="https://www.beatmyinsurance.com/terms/"
            className="hover:underline"
          >
            Terms of Use
          </a>{" "}
          | Privacy Policy
        </p>
        <div className="mt-4 flex justify-center space-x-4">
          {[
            { src: "facebook.svg", link: "https://www.facebook.com/beatmyinsurance/" },
            { src: "instagram.svg", link: "https://www.instagram.com/beatmyinsurance/" },
            { src: "youtube.svg", link: "http://www.youtube.com/@BeatMyInsurance" },
            { src: "twitter.svg", link: "https://x.com/Beatmyinsurance" },
            { src: "linkedin.svg", link: "https://www.linkedin.com/company/10372089/" },
          ].map((icon) => (
            <img
              key={icon.src}
              src={icon.src}
              alt={icon.src.split(".")[0]}
              className="w-6 h-6 cursor-pointer"
              onClick={() => window.open(icon.link, "_blank", "noopener,noreferrer")}
            />
          ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
